import styles from "./ChatMediaViewer.module.css";
import Image from "./Image"; // TODO: extract to package
import icons from "./fontello-embedded.module.css";

import VideoPlayer from "@faintlines/video-player";

import { observer } from "mobx-react-lite";
import classnames from "classnames";
import React from "react";

// TODO: use ZoomableImage
// TODO: support text

export const ChatMediaViewer = observer(({ store }) => {
    const { activeMedia } = store;
    if (!activeMedia) {
        return null;
    }

    const { video, image } = activeMedia;

    return (
        <div className={styles.viewer}>
            <div
                className={classnames(styles.closeButton, icons["icon-cancel"])}
                onClick={() => store.hideActiveMedia()}
            />
            {image ? (
                <Image className={styles.image} src={image} showSpinner />
            ) : video ? (
                <VideoPlayer
                    url={video}
                    className={styles.video}
                    playsInline
                    autoPlay
                    controls
                    width="100%"
                    height="100%"
                />
            ) : null}
        </div>
    );
});
