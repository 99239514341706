import styles from "./ChatSplitScreen.module.css";
import { ChatList } from "./ChatList";
import { Chat } from "./Chat";

import { observer } from "mobx-react-lite";
import classnames from "classnames";
import React, { useEffect, useState } from "react";

export const ChatSplitScreen = observer(({ store, appTitle }) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        function resizeHandler() {
            setIsMobile(document.body.clientWidth <= 800);
        }
        resizeHandler();
        window.addEventListener("resize", resizeHandler);
        return () => window.removeEventListener("resize", resizeHandler);
    }, []);

    return (
        <div
            className={classnames(
                styles.screen,
                store.hasActiveChat ? styles.active : null
            )}
        >
            <div className={styles.screen__list}>
                <ChatList
                    store={store}
                    appTitle={appTitle}
                    onChatSelect={(chatId) => store.setActiveChat(chatId)}
                />
            </div>
            <div className={styles.screen__chat}>
                <Chat store={store} showBackButton={isMobile} />
            </div>
        </div>
    );
});
