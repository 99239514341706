import styles from "./ChatMessage.module.css";
import icons from "./fontello-embedded.module.css";
import Image from "./Image"; // TODO: extract to package
import ChatAudioPlayer from "./ChatAudioPlayer";
import { useChatStore } from "./chatContexts";
import { getMemberById, getMemberIndex } from "./chatClientUtils";
import MarkdownContent from "./MarkdownContent";

import React from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";
import dayjs from "dayjs";
import isString from "lodash/isString";
import isDate from "lodash/isDate";

const emojiRegex = require("emoji-regex/RGI_Emoji");

const TIME_FORMAT = "hh:mm A";

function ChatMessage({ message, previousMessage, onImagesLoaded }) {
    const {
        direction,
        text,
        time,
        type,
        image,
        video,
        videoThumbnail,
        status,
        chatId,
    } = message;
    const Store = useChatStore();
    const chat = Store.getChatById(chatId) || {};
    const extraSpace = shouldExtraSpace(message, previousMessage, chat);

    if (type === "system") {
        return <SystemMessage message={message} extraSpace={extraSpace} />;
    }
    if (type === "audio") {
        return <AudioMessage message={message} extraSpace={extraSpace} />;
    }

    const emoji = text && isEmoji(text);
    const deleted = type === "deleted";
    const allowMarkdown = direction === "in";

    return (
        <div
            className={classnames(
                styles.message,
                status ? styles[status] : null,
                styles[direction],
                extraSpace ? styles.extraSpace : null
            )}
        >
            <MessageFrom message={message} />
            {video ? (
                <VideoThumbnail
                    imageUrl={videoThumbnail}
                    onImageLoaded={onImagesLoaded}
                    onClick={() => Store.showMedia({ video, text })}
                />
            ) : image ? (
                <Image
                    className={styles.image}
                    src={image}
                    showSpinner
                    onLoad={onImagesLoaded}
                    onClick={() => Store.showMedia({ image, text })}
                />
            ) : null}
            <div
                className={classnames(
                    styles.text,
                    emoji ? styles.emoji : null,
                    deleted ? styles.deleted : null
                )}
            >
                {deleted ? (
                    "You deleted this message"
                ) : allowMarkdown ? (
                    <MarkdownContent
                        markdown={text}
                        className={styles.text__contents}
                    />
                ) : (
                    text
                )}
                <span className={styles.text__spacer} />
            </div>
            <StatusLine direction={direction} time={time} status={status} />
            {status === "error" ? (
                <div className={styles.notDelivered}>{"Not delivered"}</div>
            ) : null}
        </div>
    );
}

export default observer(ChatMessage);

const MessageFrom = observer(({ message }) => {
    const Store = useChatStore();
    const { chatId, from } = message;
    const chat = Store.getChatById(chatId) || {};
    const { group, members } = chat;
    const fromMember = getMemberById(members, from);

    if (!group || !fromMember) {
        return null;
    }

    return (
        <div
            className={classnames(
                styles.from,
                styles[`c${getMemberIndex(members, from) + 1}`]
            )}
        >
            {fromMember.name}
        </div>
    );
});

function StatusLine({ direction, time, status }) {
    const icon =
        status === "pending"
            ? "clock"
            : status === "error"
            ? "warning"
            : "check";

    return (
        <div className={styles.time}>
            {formatTime(time)}
            {direction === "out" ? (
                <span
                    className={classnames(
                        styles.status__icon,
                        status ? styles[status] : null,
                        icons[`icon-${icon}`]
                    )}
                />
            ) : null}
        </div>
    );
}

function VideoThumbnail({ imageUrl, onImageLoaded, onClick }) {
    return (
        <div className={styles.videoThumbail} onClick={onClick}>
            <Image
                className={styles.videoThumbail__image}
                src={imageUrl}
                showSpinner
                onLoad={onImageLoaded}
            />
            <div
                className={classnames(
                    styles.videoThumbail__icon,
                    icons["icon-play"]
                )}
            />
        </div>
    );
}

function AudioMessage({ message, extraSpace }) {
    const { direction, text, time, status, chatId, audio } = message;

    return (
        <div
            className={classnames(
                styles.message,
                styles.audio,
                status ? styles[status] : null,
                styles[direction],
                extraSpace ? styles.extraSpace : null
            )}
        >
            <MessageFrom message={message} />
            <ChatAudioPlayer src={audio} />
            <StatusLine direction={direction} time={time} status={status} />
        </div>
    );
}

function SystemMessage({ message, extraSpace }) {
    return (
        <div
            className={classnames(
                styles.message,
                styles.system,
                extraSpace ? styles.extraSpace : null
            )}
        >
            {message.text}
        </div>
    );
}

export function isEmoji(string) {
    const regex = emojiRegex();
    if (!string) {
        return false;
    }
    const match = string.match(regex);
    return match !== null && match.length > 0 && match[0] === string;
}

export function formatTime(time) {
    const parsedTime = isString(time) || isDate(time) ? dayjs(time) : time;
    return parsedTime.format(TIME_FORMAT);
}

function shouldExtraSpace(message, previousMessage, chat) {
    if (!previousMessage) {
        return false;
    }
    if (
        message.type === "system" ||
        message.direction !== previousMessage.direction
    ) {
        return true;
    }
    return chat.group && message.from !== previousMessage.from;
}
