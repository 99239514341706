import styles from "./ChatFooter.module.css";
import { useChatStore } from "./chatContexts";
import icons from "./fontello-embedded.module.css";

import React, { useRef } from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

const ChatFooter = observer(() => {
    const Store = useChatStore();
    const { activeChat } = Store;

    return (
        <div className={styles.footer}>
            {activeChat.inactive ? <ChatFooterInactive /> : <ChatFooterForm />}
        </div>
    );
});

const ChatFooterForm = observer(() => {
    const Store = useChatStore();
    const { activePendingMessage, canSendMessage } = Store;

    const textbox = useRef(null);

    function handleSendClick() {
        if (!canSendMessage) {
            return;
        }

        Store.sendMessage();
        textbox.current.focus();
    }

    return (
        <>
            <input
                ref={textbox}
                className={styles.footer__input}
                type="text"
                placeholder="Type a message"
                value={activePendingMessage}
                onChange={(evt) =>
                    Store.setActivePendingMessage(evt.target.value)
                }
                onKeyPress={(evt) => evt.key === "Enter" && Store.sendMessage()}
            />
            <div
                className={classnames(
                    styles.footer__sendButton,
                    icons["icon-paper-plane"],
                    canSendMessage ? null : styles.disabled
                )}
                onClick={handleSendClick}
            />
        </>
    );
});

function ChatFooterInactive() {
    return (
        <div className={styles.footer__inactive}>
            {
                "You can't send messages to this group because you're no longer a participant."
            }
        </div>
    );
}

export default ChatFooter;
