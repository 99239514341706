import find from "lodash/find";
import findIndex from "lodash/findIndex";
import ImageGroup from "./group.svg";

export function getChatStatus(chat) {
    const { connected, group, members } = chat;

    const typingStatus = getChatTypingStatus(chat);
    if (typingStatus) {
        return typingStatus;
    }

    if (connected && !group) {
        return "Connected";
    }
    if (group) {
        return getMembersList(members);
    }
    return null;
}

export function getChatTypingStatus(chat) {
    const { typing, group, members } = chat;

    if (group && typeof typing === "string") {
        const member = getMemberById(members, typing);
        if (member) {
            return `${member.name} is typing...`;
        }
    }
    if (typing) {
        return "Typing...";
    }
    return null;
}

export function getMemberById(members, memberId) {
    return find(members, { id: memberId });
}

export function getMemberIndex(members, memberId) {
    return findIndex(members, { id: memberId });
}

export function getChatPicture(chat) {
    return chat.picture || (chat.group ? ImageGroup : null);
}

function getMembersList(members) {
    return (members || [])
        .map((x) => x.name)
        .concat(["you"])
        .join(", ");
}
