import styles from "./Image.module.css";

import React, { useState, useEffect, useRef } from "react";
import classnames from "classnames";

// TODO: extract to a package

export default function Img({
	src,
	alt,
	className,
	showSpinner,
	onLoad,
	...props
}) {
	const [loaded, setLoaded] = useState(false);

	const isMounted = useRef(true);
	useEffect(
		() => () => {
			isMounted.current = false;
		},
		[]
	);

	useEffect(() => {
		const image = new Image();

		image.onload = () => {
			if (isMounted.current) {
				setLoaded(true);
			}
		};
		image.onerror = () => {
			setTimeout(() => {
				if (isMounted.current) {
					image.src = src;
				}
			}, 500);
		};

		image.src = src;
	}, [src]);

	if (loaded) {
		return (
			<img
				className={classnames(styles.image, className)}
				src={src}
				alt={alt}
				onLoad={onLoad}
				{...props}
			/>
		);
	}

	return showSpinner ? <Spinner /> : null;
}

function Spinner() {
	return (
		<div className={styles.spinner}>
			<div className={styles.spinner__inner} />
			<div className={styles.spinner__inner} />
			<div className={styles.spinner__inner} />
			<div className={styles.spinner__inner} />
		</div>
	);
}
