import styles from "./ChatList.module.css";
import { formatTime } from "./timeUtils";
import {
    ChatStoreContext,
    ChatThemeContext,
    useChatStore,
} from "./chatContexts";
import { getChatTypingStatus, getChatPicture } from "./chatClientUtils";

import React from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";
import last from "lodash/last";

export function ChatList({ appTitle, store, theme, onChatSelect }) {
    return (
        <ChatThemeContext.Provider value={theme || {}}>
            <ChatStoreContext.Provider value={store}>
                <ChatListContents
                    appTitle={appTitle}
                    onChatSelect={onChatSelect}
                />
            </ChatStoreContext.Provider>
        </ChatThemeContext.Provider>
    );
}

const ChatListContents = observer(({ appTitle, onChatSelect }) => {
    const State = useChatStore();
    const { sortedChatList, activeChatId } = State;

    return (
        <div className={styles.list}>
            <div className={styles.list__title}>{appTitle || "Chat App"}</div>
            {sortedChatList.length === 0 ? (
                <div className={styles.list__noChats}>{"No chats yet..."}</div>
            ) : null}
            {sortedChatList.map((chat) => (
                <ChatListItem
                    key={chat.id}
                    chat={chat}
                    active={chat.id === activeChatId}
                    onClick={() => onChatSelect && onChatSelect(chat.id)}
                />
            ))}
        </div>
    );
});

const ChatListItem = observer(({ chat, active, onClick }) => {
    if (chat.hideEmpty && chat.messages.length === 0) {
        return null;
    }

    const lastMessage = last(chat.messages);

    return (
        <ChatListItemView
            title={chat.title}
            time={lastMessage ? lastMessage.time : null}
            status={getStatus(chat, lastMessage)}
            typing={chat.typing}
            deleted={lastMessage && lastMessage.type === "deleted"}
            active={active}
            picture={getChatPicture(chat)}
            unreadCount={chat.unreadCount}
            onClick={onClick}
        />
    );
});

function getStatus(chat, lastMessage) {
    const typingStatus = getChatTypingStatus(chat);
    if (typingStatus) {
        return typingStatus;
    }

    if (!lastMessage) {
        return null;
    }
    if (lastMessage.type === "deleted") {
        return "You deleted this message";
    }

    return lastMessage.text;
    // TODO: return markdownToText(lastMessage.text);
}

const ChatListItemView = React.memo(
    ({
        picture,
        title,
        time,
        status,
        typing,
        deleted,
        active,
        unreadCount,
        onClick,
    }) => (
        <div
            className={classnames(styles.item, active ? styles.active : null)}
            onClick={onClick}
        >
            <div
                className={styles.item__picture}
                style={{ backgroundImage: `url(${picture})` }}
            />
            <div className={styles.item__details}>
                <div className={styles.item__title}>
                    {title}
                    {time ? (
                        <div className={styles.item__time}>
                            {formatTime(time)}
                        </div>
                    ) : null}
                </div>
                <div
                    className={classnames(
                        styles.item__status,
                        typing ? styles.typing : null,
                        deleted ? styles.deleted : null
                    )}
                >
                    {status}
                    {unreadCount ? (
                        <div className={styles.item__unread}>{unreadCount}</div>
                    ) : null}
                </div>
            </div>
        </div>
    )
);
