import styles from "./Chat.module.css";
import ChatMessage from "./ChatMessage";
import ChatFooter from "./ChatFooter";
import {
    ChatStoreContext,
    ChatThemeContext,
    useChatStore,
    useChatTheme,
} from "./chatContexts";
import { getChatStatus, getChatPicture } from "./chatClientUtils";
import icons from "./fontello-embedded.module.css";

import React, { useRef, useEffect } from "react";
import { observer } from "mobx-react-lite";
import classnames from "classnames";

export function Chat({ showBackButton, store, theme }) {
    return (
        <ChatThemeContext.Provider value={theme || {}}>
            <ChatStoreContext.Provider value={store}>
                <div className={styles.chat}>
                    <ChatContents showBackButton={showBackButton} />
                </div>
            </ChatStoreContext.Provider>
        </ChatThemeContext.Provider>
    );
}

const ChatContents = observer(({ showBackButton }) => {
    const Store = useChatStore();
    const { activeChat: chat } = Store;

    if (!chat) {
        return null;
    }

    const { title, messages } = chat;

    return (
        <>
            <ChatHeader
                picture={getChatPicture(chat)}
                title={title}
                status={getChatStatus(chat)}
                showBackButton={showBackButton}
            />
            <ChatMessages messages={messages} />
            <ChatFooter />
        </>
    );
});

const ChatHeader = observer(({ picture, title, status, showBackButton }) => {
    const Store = useChatStore();

    return (
        <div className={styles.header}>
            {showBackButton ? (
                <div
                    className={classnames(
                        styles.header__back,
                        icons["icon-left-open-big"]
                    )}
                    onClick={() => Store.unsetActiveChat()}
                />
            ) : null}
            {picture ? (
                <div
                    className={styles.header__picture}
                    style={{ backgroundImage: `url(${picture})` }}
                />
            ) : null}
            <div className={styles.header__details}>
                <div className={styles.header__title}>{title}</div>
                {status ? (
                    <div className={styles.header__status}>{status}</div>
                ) : null}
            </div>
        </div>
    );
});

const ChatMessages = observer(({ messages }) => {
    const scroller = useRef();

    function scrollToBottom() {
        scroller.current.scrollTop = scroller.current.scrollHeight;
    }

    useEffect(() => scrollToBottom(), [messages.length]);
    useEffect(() => {
        // This is mostly for handling keyboard open/close on mobile, which resizes the window
        window.addEventListener("resize", scrollToBottom);
        return () => window.removeEventListener("resize", scrollToBottom);
    }, []);

    const theme = useChatTheme();

    return (
        <div
            className={styles.messagesWrapper}
            ref={scroller}
            style={{ backgroundImage: theme.bg ? `url(${theme.bg})` : null }}
        >
            <div className={styles.messages}>
                {messages.map((message, i) => (
                    <ChatMessage
                        key={i}
                        message={message}
                        previousMessage={i > 0 ? messages[i - 1] : null}
                        onImagesLoaded={() => scrollToBottom()}
                    />
                ))}
            </div>
        </div>
    );
});
