import React from "react";
import showdown from "showdown";

let markdownConverter = null;

export default function MarkdownContent({ markdown, className }) {
    return (
        <div
            className={className}
            dangerouslySetInnerHTML={{
                __html: getMarkdownConverter().makeHtml(markdown),
            }}
        />
    );
}

function getMarkdownConverter() {
    if (!markdownConverter) {
        markdownConverter = new showdown.Converter();
        markdownConverter.setFlavor("github");
        markdownConverter.setOption("simplifiedAutoLink", false);
        markdownConverter.setOption("underline", true);
        markdownConverter.setOption("openLinksInNewWindow", true);
    }

    return markdownConverter;
}
