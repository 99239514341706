import { createContext, useContext } from "react";

export const ChatThemeContext = createContext(null);
export function useChatTheme() {
    return useContext(ChatThemeContext);
}

export const ChatStoreContext = createContext(null);
export function useChatStore() {
    return useContext(ChatStoreContext);
}
