import dayjs from "dayjs";
import isString from "lodash/isString";
import isDate from "lodash/isDate";

const TIME_FORMAT = "hh:mm A";

export function formatTime(time) {
    const parsedTime = isString(time) || isDate(time) ? dayjs(time) : time;
    return parsedTime.format(TIME_FORMAT);
}
